import { FC } from 'react'
import { default as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material/Menu'

export type MenuProps = Pick<
  MuiMenuProps,
  | 'open'
  | 'children'
  | 'onClose'
  | 'anchorEl'
  | 'disablePortal'
  | 'disableScrollLock'
  | 'anchorOrigin'
  | 'transformOrigin'
  | 'id'
  | 'MenuListProps'
  | 'disableRestoreFocus'
>

/**
 * A menu displays a list of choices on a temporary surface.
 * It appears when the user interacts with a button, or other control.
 */

export const Menu: FC<MenuProps> = (props: MenuProps) => <MuiMenu {...props} elevation={2} />
