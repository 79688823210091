import { SVGMotionProps, m as motion } from 'framer-motion'

const Path = (props: SVGMotionProps<SVGPathElement>): JSX.Element => (
  <motion.path fill="transparent" strokeWidth="3" stroke="white" strokeLinecap="round" {...props} />
)

export const MenuToggle = ({
  toggle,
  isOpen
}: {
  isOpen: boolean
  toggle: () => void
}): JSX.Element => (
  <motion.button
    initial={false}
    animate={isOpen ? 'open' : 'closed'}
    onClick={toggle}
    aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
    aria-expanded={isOpen}
    style={{
      display: 'block',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '2px',
      cursor: 'pointer'
    }}
    whileFocus={{ outline: 'none' }}
    whileTap={{ outline: 'none' }}
  >
    <svg width="22" height="22" viewBox="0 0 22 22">
      <Path
        variants={{
          closed: { d: 'M 2 4.5 L 20 4.5' },
          open: { d: 'M 3 18.5 L 17 4.5' }
        }}
        stroke={'black'}
      />
      <Path
        d="M 2 11.423 L 20 11.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
        stroke={'black'}
      />
      <Path
        variants={{
          closed: { d: 'M 2 18.346 L 20 18.346' },
          open: { d: 'M 3 4.5 L 17 18.346' }
        }}
        stroke={'black'}
      />
    </svg>
  </motion.button>
)
