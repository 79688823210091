import { Box } from '../Box/Box'
import { FC, ReactElement } from 'react'
import { ChipProps as MuiChipProps } from '@mui/material/Chip'
import { SxProps, useTheme } from '@mui/material/styles'
import { Typography, TypographyProps } from '../Typography/Typography'

export type ChipBadgeProps = Pick<MuiChipProps, 'label'> & {
  /**
   * @default info
   */
  color?:
    | 'info'
    | 'success'
    | 'error'
    | 'warning'
    | 'upgrade'
    | 'secondary'
    | 'primary'
    | 'tertiary'
  icon?: ReactElement
  endIcon?: ReactElement
  fontColor?: string
  /**
   * @default medium
   */
  size?: 'small' | 'medium'
  'data-testid'?: string
  sx?: SxProps
}

/**
 * ChipBadge mimicks the appearance of the Chip component, but is NOT interactable.
 *
 * ChipBadge should be used as an indicator to convey passive information.
 */
export const ChipBadge: FC<ChipBadgeProps> = ({
  icon,
  label,
  endIcon,
  color = 'info',
  fontColor,
  size = 'medium',
  'data-testid': dataTestId,
  sx
}) => {
  const theme = useTheme()

  const paddingValues = {
    small: `0 ${theme.spacing(1.5)}`,
    medium: `0 ${theme.spacing(2)}`,
    iconOnly: { small: theme.spacing(0.75), medium: theme.spacing(1) }
  }

  const heightValues = {
    small: theme.spacing(5),
    medium: theme.spacing(6),
    iconOnly: 'unset'
  }

  const typographyValues = {
    small: 'labelSmall',
    medium: 'labelMedium'
  }

  const iconSizes = {
    small: theme.spacing(3.5),
    medium: theme.spacing(4)
  }

  const badgePadding = label ? paddingValues[size] : paddingValues.iconOnly[size]
  const badgeHeight = label ? heightValues[size] : heightValues.iconOnly

  const contentColor = theme.palette[color].main

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: contentColor,
        borderRadius: theme => theme.spacing(25),
        p: badgePadding,
        height: badgeHeight,
        width: 'max-content',
        textTransform: 'uppercase',
        columnGap: theme => theme.spacing(1),
        '.MuiSvgIcon-root': {
          fontSize: iconSizes[size],
          color: fontColor || theme.palette.getContrastText(contentColor)
        },
        ...sx
      }}
    >
      {icon}
      {label && (
        <Typography
          variant={typographyValues[size] as TypographyProps['variant']}
          color={fontColor || theme.palette.getContrastText(contentColor)}
        >
          {label}
        </Typography>
      )}
      {endIcon && label && endIcon}
    </Box>
  )
}
