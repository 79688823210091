import { NavLinkProps } from '../../..'
import { boxShadow, height, padding } from 'styled-system'
import styled from 'styled-components'

export const NavLinkBase = styled.div<NavLinkProps>`
  display: flex;
  height: 100%;
  position: relative;
  ${padding};
  ${boxShadow};
  ${height};
  align-items: center;
  &:first-child {
    margin-left: 10px;
  }
  &.align-right {
    margin-left: auto;
  }
  &.align-right + .align-right {
    margin-left: 0;
  }
  cursor: ${props => props.cursor};
`

export const NavLink = ({
  children,
  padding = '0',
  alignRight = false,
  cursor = 'pointer',
  ...rest
}: NavLinkProps): JSX.Element => {
  return (
    <NavLinkBase
      cursor={cursor}
      padding={padding}
      className={alignRight ? 'align-right' : ''}
      {...rest}
    >
      {children}
    </NavLinkBase>
  )
}
