import { Box, Button, Link, Stack, Typography } from '@dtx-company/design-system/src'
import { ChipBadge } from '@dtx-company/design-system/src/components/ChipBadge/ChipBadge'
import { DashboardDisplay } from './DashboardDisplay'
import { FC, ReactElement, useMemo, useState } from 'react'
import { IconComponent } from '@dtx-company/design-system/src/types/util'
import { NavLinksProps } from './NavLinks.types'
import { Shadows } from '@mui/material/styles'
import { SignInOptions } from './SignInOptions'
import { Tooltip } from '@mui/material'
import {
  TopNavDocument,
  TopNavDocumentDataBodyDesktopSlice,
  TopNavDocumentDataBodyDesktopSliceItem,
  TopNavDocumentDataBodyMobileSlice
} from '@dtx-company/prismic-codegen'
import { asText } from '@prismicio/client'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { topNavData } from './topNavData'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Image from 'next/image'

const getIcon = (
  navItem: TopNavDocumentDataBodyDesktopSlice | TopNavDocumentDataBodyMobileSlice,
  activeNav: TopNavDocumentDataBodyDesktopSlice | TopNavDocumentDataBodyMobileSlice | null
): IconComponent | undefined => {
  if (navItem.items?.length > 0)
    return activeNav?.id === navItem.id ? ExpandLessIcon : ExpandMoreIcon
  return undefined
}

export const MarketingNavLinks: FC<NavLinksProps> = ({
  isLoggedIn,
  isFPHomepage = false,
  topNavPrismic
}) => {
  const { isMobile } = useScreenSizeBreakpoints()
  const desktopNav = useMemo(() => {
    return (topNavPrismic?.data?.body || []).filter(item => item.slice_type === 'desktop')
  }, [topNavPrismic])
  const [activeNav, setActiveNav] = useState<
    TopNavDocumentDataBodyDesktopSlice | TopNavDocumentDataBodyMobileSlice | null
  >(null)

  return (
    <>
      <Stack
        onMouseLeave={() => {
          setActiveNav(null)
        }}
        direction={'row'}
        data-testid="marketing-nav-desktop"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          ml: 6,
          width: '100%'
        }}
      >
        <NavigationMenuTooltip activeNav={activeNav} prismic={topNavPrismic || topNavData}>
          <Stack direction={'row'}>
            {desktopNav.map(navItem => (
              <Box
                key={navItem.id}
                onMouseEnter={e => {
                  fireAnalyticsEvent('Global_MarketingNav_Hovered_NavItem', {
                    value: asText(navItem.primary.header)
                  })
                  setActiveNav(navItem)
                }}
                onClick={() => {
                  fireAnalyticsEvent('Global_MarketingNav_Clicked_NavItem', {
                    value: asText(navItem.primary.header)
                  })
                  setActiveNav(navItem)
                }}
                sx={theme => ({
                  //TODO: this is a temporary solution. Move it to the button
                  'button, a': {
                    borderBottom: `2px solid transparent`,
                    textDecorator: 'none'
                  },
                  'a:hover, button:hover': {
                    backgroundColor: 'transparent',
                    borderBottom: `2px solid ${theme.palette.tertiary.main}`,
                    color: theme.palette.tertiary.main
                  }
                })}
              >
                <Button
                  variant={'text'}
                  href={
                    (navItem.primary.header_link as any)?.url
                      ? (navItem.primary.header_link as any)?.url
                      : undefined
                  }
                  target={
                    navItem.primary.header_link && navItem.primary.isexternal ? '_blank' : undefined
                  }
                  endIcon={getIcon(navItem, activeNav)}
                >
                  {asText(navItem.primary.header)}
                </Button>
              </Box>
            ))}
          </Stack>
        </NavigationMenuTooltip>

        {/* Auth/Dashboard buttons */}
        {isLoggedIn && !isMobile && <DashboardDisplay />}
        {!isLoggedIn && <SignInOptions isFPHomepage={isFPHomepage} />}
      </Stack>
    </>
  )
}

export const NavigationMenuTooltip: FC<{
  activeNav?: TopNavDocumentDataBodyDesktopSlice | TopNavDocumentDataBodyMobileSlice | null
  prismic: TopNavDocument
  children: ReactElement
}> = ({ activeNav, prismic, children }) => {
  return (
    <Tooltip
      arrow={false}
      open={Boolean(activeNav)}
      componentsProps={{
        popper: {
          sx: {
            width: '100vw'
          }
        },
        tooltip: {
          sx: {
            backgroundColor: 'white',
            color: 'black',
            width: '100%',
            maxWidth: '100%',
            boxShadow: theme => (theme.shadows as Shadows)[1],
            p: 0
          }
        }
      }}
      title={
        activeNav?.items &&
        activeNav?.items.length > 0 && (
          <Stack direction={'row'}>
            <Box sx={{ pt: 11.5, pr: 13.5, pb: 13.5, pl: 5, width: '75%' }}>
              <NavigationMenu navItem={activeNav} />
            </Box>
            <Stack
              gap={6}
              sx={theme => ({
                pt: 11.5,
                pr: 13.5,
                pb: 13.5,
                pl: 5,
                backgroundColor: theme.palette.surface['1'],
                borderLeft: `1px solid ${theme.palette.onSurface.inverseSecondary}`,
                width: '25%'
              })}
            >
              <Typography variant={'subtitleLarge'} color={theme => theme.palette.tertiary.main}>
                {prismic.data.contact_us_title}
              </Typography>
              <Stack gap={2}>
                <Typography variant={'subtitleMedium'}>
                  {prismic.data.contact_us_subtitle}
                </Typography>
                <Typography variant={'bodySmall'} color={'primary.light'}>
                  {prismic.data.contactus_description}
                </Typography>
              </Stack>
              <Box
                sx={{
                  a: {
                    textDecoration: 'underline',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary.main'
                  }
                }}
              >
                <Link href={(prismic.data.contact_us_link as any)?.url || '/'}>
                  <Typography variant={'labelLarge'}>
                    {prismic.data.contact_us_link_text}
                  </Typography>
                  <ArrowForwardIcon />
                </Link>
              </Box>
              {prismic.data.contact_us_image?.url && (
                <Image
                  style={{ borderRadius: '6px' }}
                  width={290}
                  height={181}
                  src={prismic.data.contact_us_image.url}
                  alt={prismic.data.contact_us_image.alt || 'Contact us'}
                  loading={'lazy'}
                />
              )}
            </Stack>
          </Stack>
        )
      }
    >
      {children}
    </Tooltip>
  )
}

export const NavigationMenu: FC<{
  navItem?: TopNavDocumentDataBodyDesktopSlice | TopNavDocumentDataBodyMobileSlice | null
}> = ({ navItem }) => {
  const subsections: Record<string, TopNavDocumentDataBodyDesktopSliceItem[]> = useMemo(
    () =>
      ((navItem?.items || []) as TopNavDocumentDataBodyDesktopSliceItem[]).reduce(
        (
          acc: Record<string, TopNavDocumentDataBodyDesktopSliceItem[]>,
          item: TopNavDocumentDataBodyDesktopSliceItem
        ) => {
          if (item.subsection && !acc[item.subsection]) {
            acc[item.subsection] = [item]
          } else if (item.subsection) {
            acc[item.subsection].push(item)
          }
          return acc
        },
        {}
      ),
    [navItem]
  )

  return (
    <Stack
      sx={{
        display: 'grid',
        gap: { xs: 4, md: 4, lg: 6 },
        gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr 1fr' }
      }}
    >
      {Object.keys(subsections || {}).map(subsection => (
        <Stack key={`subsection-${subsection}`} gap={4}>
          <Typography variant={'subtitleMedium'} color={theme => theme.palette.tertiary.main}>
            {subsection}
          </Typography>

          <Stack
            gap={{ xs: 4, lg: 6 }}
            sx={{
              display: 'flex',
              pl: { xs: 4, lg: 0 }
            }}
          >
            {subsections[subsection]?.map((item: TopNavDocumentDataBodyDesktopSliceItem, index) => (
              <Stack gap={2} key={`${subsection}-${index}`}>
                <Stack
                  direction={'row'}
                  gap={2}
                  alignItems={'center'}
                  sx={theme => ({
                    a: {
                      color: theme.palette.primary.main,
                      borderBottom: `1px solid transparent`,
                      textDecoration: 'none',
                      textDecorationColor: 'transparent'
                    },
                    'a:hover': {
                      backgroundColor: 'transparent',
                      borderBottom: `1px solid ${theme.palette.tertiary.main}`,
                      color: theme.palette.tertiary.main,
                      textDecoration: 'none',
                      textDecorationColor: 'transparent'
                    }
                  })}
                >
                  <Link
                    href={(item.dropdown_link as any)?.url ? (item.dropdown_link as any)?.url : '/'}
                    onClick={() =>
                      fireAnalyticsEvent('Global_MarketingNav_Clicked_NavItem', {
                        value: asText(item.link_title)
                      })
                    }
                    target={item.dropdown_link && item.isexternal ? '_blank' : undefined}
                  >
                    <Typography variant={'bodyLarge'}>{asText(item.link_title)}</Typography>
                  </Link>
                  {item.badge && <ChipBadge label={item.badge} size={'small'} color={'primary'} />}
                </Stack>
                <Typography variant={'bodySmall'} color={'primary.light'}>
                  {asText(item.link_subtext)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
