import { Box, Stack } from '@dtx-company/design-system/src'
import { FC, ReactNode } from 'react'
import { MenuToggle } from '../styles'
import { MobileLeftNav } from './MobileNavMenu'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { TopNavDocument } from '@dtx-company/prismic-codegen'
import { setNavLeftMenu } from '@dtx-company/inter-app/src/redux/slices/config-slice'
import { useDispatch, useSelector } from 'react-redux'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'

export interface NavProps {
  logo?: ReactNode
  children: ReactNode
  isPositionFixed?: boolean
  topNavPrismic: TopNavDocument
}

export const TopNavV2: FC<NavProps> = ({ logo, children, isPositionFixed, ...rest }: NavProps) => {
  const dispatch = useDispatch()
  const { isLeftMenuOpen } = useSelector((state: RootState) => state.appReducer.configReducer)
  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          position: isPositionFixed ? 'fixed' : 'relative',
          top: isPositionFixed ? 0 : undefined,
          maxWidth: '100vw',
          px: {
            xs: 3,
            md: 4
          },
          py: 2
        }}
        {...rest}
      >
        {logo}
        {children}
        <Box
          sx={{ display: { xs: 'flex', lg: 'none' }, visibility: { xs: 'visible', lg: 'none' } }}
        >
          <MenuToggle
            isOpen={isLeftMenuOpen}
            toggle={() => {
              events.Global_Navigation_Clicked_HamburgerMenu()
              dispatch(setNavLeftMenu({ open: !isLeftMenuOpen }))
            }}
          />
        </Box>
      </Stack>
      <MobileLeftNav open={isLeftMenuOpen} topNavPrismic={rest.topNavPrismic} />
    </>
  )
}
