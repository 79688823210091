import { forwardRef } from 'react'
import MuiMenuList, { MenuListProps as MuiMenuListProps } from '@mui/material/MenuList'

export type MenuListProps = Pick<
  MuiMenuListProps,
  'children' | 'autoFocusItem' | 'sx' | 'ref' | 'id' | 'onKeyDown' | 'dense'
>

/**
 * Displays a Menu but without opening/closing. Should wrap MenuItem components.
 */
export const MenuList = forwardRef<HTMLUListElement, MenuListProps>(
  ({ children, ...rest }, ref) => (
    <MuiMenuList ref={ref} {...rest}>
      {children}
    </MuiMenuList>
  )
)

MenuList.displayName = 'MenuList'
