import { Button } from '@dtx-company/design-system/src'
import { FC } from 'react'
import { Login } from '@mui/icons-material'
import { NavLink } from '@dtx-company/shared-components/src'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import Link from 'next/link'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'

export const DashboardDisplay: FC = () => (
  <NavLink alignRight>
    <Button
      fullWidth
      component={Link}
      href={Routes.ANALYTICS_CODES}
      color="tertiary"
      startIcon={Login}
      data-testid="marketing-nav-desktop-dashboard"
      onClick={() => events.Global_MarketingNav_Clicked_DashboardButton()}
    >
      Dashboard
    </Button>
  </NavLink>
)
