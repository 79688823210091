import { AnimatePresence, m as motion } from 'framer-motion'
import { Box, Button, Stack, Typography, theme } from '@dtx-company/design-system/src'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { IconComponent } from '@dtx-company/design-system/src/types/util'
import { Login } from '@mui/icons-material'
import { NavigationMenu } from '../../../../components/layout/header/v2/MarketingNavLinks'
import { NextLinkWrap } from '@app/page/src/components/util/NextLinkUtils'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { TopNavDocument, TopNavDocumentDataBodyMobileSlice } from '@dtx-company/prismic-codegen'
import { asText } from '@prismicio/client'
import { closeMenuIcon, flowcodeNavLogo } from '@app/common/src/constants/assets'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { setNavLeftMenu } from '@dtx-company/inter-app/src/redux/slices/config-slice'
import { topNavData } from '../../../../components/layout/header/v2/topNavData'
import { useDispatch } from 'react-redux'
import { useNavigationRoutes } from '../../../../hooks/useNavigationRoutes'
import { useRouter } from 'next/router'
import Collapse from '@mui/material/Collapse'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Image from 'next/image'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'
import useTranslation from 'next-translate/useTranslation'

export interface MobileNavMenuProps {
  open?: boolean
  items?: MobileNavMenuItemProps[]
  userIsLoggedInOnMobile?: boolean
  topNavPrismic: TopNavDocument
}

interface MobileNavMenuItemProps {
  header?: string
  subItems?: MobileNavMenuSubItemProps[]
}

interface MobileNavMenuSubItemProps {
  header: string
  subHeader?: string
  link?: string
}
export const MobileMarketingNavMenu: FC<MobileNavMenuProps> = props => {
  const { t } = useTranslation('nav')
  const dispatch = useDispatch()
  const setMenuClosed = useCallback(() => dispatch(setNavLeftMenu({ open: false })), [dispatch])
  const router = useRouter()

  const mobileNav: TopNavDocumentDataBodyMobileSlice[] = useMemo(() => {
    return (props.topNavPrismic?.data?.body || []).filter(
      item => item.slice_type === 'mobile'
    ) as TopNavDocumentDataBodyMobileSlice[]
  }, [props.topNavPrismic])
  const [expendedItemsIds, setExpendedItemsIds] = useState<string | undefined>(undefined)

  return (
    <Stack
      direction="column"
      gap={9}
      sx={{
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'auto',
        p: 4,
        width: '100vw',
        zIndex: 1001
      }}
      data-testid="marketing-nav-mobile"
    >
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
        <Image src={flowcodeNavLogo} alt="logo" width={121} height={24} />
        <Image
          role="button"
          src={closeMenuIcon}
          onClick={() => {
            events.Global_Navigation_Closed_MobileNav()
            setMenuClosed()
          }}
          width={21}
          height={21}
          alt="close"
        />
      </Stack>
      <Stack gap={10}>
        <Stack justifyContent={'start'}>
          {(mobileNav || []).map(item => (
            <MobileNavMenuItem
              key={item.id}
              item={item}
              showSubItems={expendedItemsIds}
              setShowSubItems={setExpendedItemsIds}
            />
          ))}
        </Stack>
        {props.userIsLoggedInOnMobile ? (
          <Button
            size="large"
            fullWidth
            color="tertiary"
            data-testid="mobile-dashboard-button"
            startIcon={Login}
            href={Routes.ANALYTICS_CODES}
            onClick={() => {
              setMenuClosed()
              events.Global_MarketingNav_Clicked_DashboardButton()
            }}
          >
            {t('Header.v2.Dashboard')}
          </Button>
        ) : (
          <Stack direction="row" gap={2}>
            <NextLinkWrap flexShrink={0} width="50%" data-testid="sign-up" href={Routes.SIGN_UP}>
              <Button
                size="large"
                fullWidth
                color="tertiary"
                onClick={() => {
                  events.Global_Navigation_MobileNav_Clicked_SignUp()
                  setMenuClosed()
                }}
                data-testid="marketing-nav-mobile-signup"
              >
                {t('Header.v2.SignUpMobile')}
              </Button>
            </NextLinkWrap>

            <NextLinkWrap
              flexShrink={0}
              width="50%"
              data-testid="sign-in"
              href={{
                pathname: Routes.SIGN_IN,
                query: { ...router.query }
              }}
            >
              <Button
                fullWidth
                size="large"
                variant={'outlined'}
                color="secondary"
                onClick={() => {
                  events.Global_Navigation_MobileNav_Clicked_SignIn()
                  setMenuClosed()
                }}
                data-testid="marketing-nav-mobile-signin"
              >
                {t('Header.v2.SignIn')}
              </Button>
            </NextLinkWrap>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export const MobileLeftNav: FC<MobileNavMenuProps> = props => {
  const { userIsLoggedInOnMobile } = useNavigationRoutes()

  return (
    <MobileLeftNavWrapper open={props.open}>
      <MobileMarketingNavMenu
        {...props}
        topNavPrismic={props.topNavPrismic || topNavData}
        userIsLoggedInOnMobile={userIsLoggedInOnMobile}
      />
    </MobileLeftNavWrapper>
  )
}

const MobileLeftNavWrapper: FC<{ open?: boolean; children: ReactNode }> = ({ open, children }) => (
  <AnimatePresence>
    {open && (
      <motion.div
        initial={{ translateX: '-100vw' }}
        exit={{ translateX: '-100vw' }}
        animate={{ translateX: '0px' }}
        transition={{ duration: 0.25 }}
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100001,
          backgroundColor: theme.palette.background.paper,
          width: '100%',
          overflowY: 'hidden',
          maxHeight: '100vh'
        }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
)

const MobileNavMenuItem: FC<{
  item: TopNavDocumentDataBodyMobileSlice
  showSubItems?: string
  setShowSubItems: (id?: string) => void
}> = ({ item, showSubItems, setShowSubItems }) => {
  const getIcon = (item: TopNavDocumentDataBodyMobileSlice): IconComponent | undefined => {
    if (item.items.length === 0) return undefined
    return showSubItems === item.id ? ExpandLessIcon : ExpandMoreIcon
  }
  return (
    <Box
      sx={theme => ({
        '&': { width: 'max-content' }, //TODO: this is a temporary solution. Move it to the button
        'button, a': {
          borderBottom: `2px solid transparent`,
          textDecorator: 'none'
        },
        'a:hover, button:hover': {
          backgroundColor: 'transparent',
          borderBottom: `2px solid ${theme.palette.tertiary.main}`,
          color: theme.palette.tertiary.main
        }
      })}
    >
      <Button
        variant={'text'}
        data-testid={`marketing-nav-mobile-${asText(item.primary.header)}`}
        onClick={() => {
          fireAnalyticsEvent('Global_Navigation_Clicked_MobileNav', {
            value: asText(item.primary.header)
          })
          if (showSubItems !== item.id) setShowSubItems(item.id)
          else setShowSubItems(undefined)
        }}
        color={showSubItems === item.id ? 'tertiary' : 'primary'}
        href={
          (item.primary.header_link as any)?.url
            ? (item.primary.header_link as any)?.url
            : undefined
        }
        target={item.primary.header_link && item.primary.isexternal ? '_blank' : undefined}
        endIcon={getIcon(item)}
      >
        <Typography variant={'subtitleLarge'}>{asText(item.primary.header)}</Typography>
      </Button>
      <Collapse in={showSubItems === item.id} timeout="auto">
        <Stack sx={{ pl: 4 }}>
          <NavigationMenu navItem={item} />
        </Stack>
      </Collapse>
    </Box>
  )
}
