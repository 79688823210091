import { Box, Stack } from '@dtx-company/design-system/src'
import { FC } from 'react'
import { FLOWCODE_MARKETING_BASE_URL } from '@dtx-company/true-common/src/constants/routes'
import { HeaderLogo } from '../../layout/header/v2/HeaderLogo'
import { MarketingNavLinks } from '../../layout/header/v2/MarketingNavLinks'
import { MarketingTopNavProps } from './MarketingTopNavProps'
import { TopNavV2 } from '../../../containers/nav/top/v2'

export const MarketingTopNav: FC<MarketingTopNavProps> = props => {
  const logo = <HeaderLogo href={FLOWCODE_MARKETING_BASE_URL} />
  //the same as for mobile menu. Mobile menu is part of TopNavV2
  return (
    <TopNavV2 {...props} logo={logo}>
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          flex: 1,
          height: '100%',
          width: '100%'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: { xs: 'none', lg: 'flex' },
            visibility: { xs: 'none', lg: 'visible' }
          }}
        >
          <MarketingNavLinks
            isLoggedIn={props.isLoggedIn}
            isFPHomepage={props.isFlowpageRoute}
            topNavPrismic={props.topNavPrismic}
          />
        </Box>
      </Stack>
    </TopNavV2>
  )
}
