import { Button, Menu, MenuList, Stack, Typography } from '@dtx-company/design-system/src'
import { FC, useMemo, useRef, useState } from 'react'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { MarketingPageRoutes, Routes } from '@dtx-company/true-common/src/constants/routes'
import { MenuItem } from '@mui/material'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useRouter } from 'next/router'
import Link from 'next/link'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'
import useTranslation from 'next-translate/useTranslation'

export const SignInOptions: FC<{ isFPHomepage: boolean }> = ({ isFPHomepage }) => {
  const { t } = useTranslation('nav')
  const router = useRouter()
  const query = useMemo(() => {
    return {
      ...router.query,
      flowpage: isFPHomepage
    }
  }, [isFPHomepage, router.query])
  const [openSignIn, setOpenSignIn] = useState(false)
  const signInRef = useRef<HTMLButtonElement | null>(null)
  return (
    <Stack direction={'row'} gap={4}>
      <Button
        color="tertiary"
        href={MarketingPageRoutes.CONTACT_SALES}
        onClick={() => fireAnalyticsEvent('Global_MarketingNav_Clicked_book_a_demo')}
        data-testid="book-demo-button"
      >
        {t('Header.v2.ContactUs')}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        ref={signInRef}
        onClick={() => {
          events.Global_MarketingNav_Clicked_SigninButton()
          setOpenSignIn(!openSignIn)
        }}
        data-testid="sign-in-button"
      >
        {t('Header.v2.SignIn')}
      </Button>
      <Menu
        open={openSignIn}
        onClose={() => setOpenSignIn(false)}
        anchorEl={signInRef.current}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left'
        }}
      >
        <MenuList
          autoFocusItem
          data-testid="log-in-menu"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <MenuItem
            component={Link}
            divider={true}
            href={{ pathname: FLOWCODE_ROOT + Routes.SIGN_IN, query }}
            data-testid={'flowcode-1-sign-in'}
            onClick={() => {
              events.Global_MarketingNav_Clicked_SigninButton()
            }}
          >
            <Stack>
              <Typography variant={'subtitleSmall'}>Flowcode 1</Typography>
              <Typography color={'primary.light'}>Our classic QR code manager.</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            component={Link}
            href={{ pathname: MarketingPageRoutes.FLOWCODE_2_SIGN_IN, query }}
            data-testid={'flowcode-2-sign-in'}
            onClick={() => {
              fireAnalyticsEvent('Global_MarketingNav_Clicked_SigninFlowcode2Button')
            }}
          >
            <Stack>
              <Typography variant={'subtitleSmall'}>Flowcode 2</Typography>
              <Typography color={'primary.light'}>Our newest conversion platform.</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  )
}
