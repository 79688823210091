import { FLOWCODE_MARKETING_BASE_URL } from '@dtx-company/true-common/src/constants/routes'
import { Stack } from '@dtx-company/design-system/src'
import { flowcodeNavLogo } from '@app/common/src/constants/assets'
import Image from 'next/image'
import Link, { LinkProps } from 'next/link'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'

export interface HeaderLogoProps {
  href?: LinkProps['href']
  isNextLink?: boolean
}

export function HeaderLogo({ isNextLink = false, href }: HeaderLogoProps): JSX.Element {
  const alt = 'Flowcode Logo'
  const logoImage = <Image src={flowcodeNavLogo} alt={alt} width={144} height={22} priority />

  return (
    <Stack
      data-testid="logo-wrapper"
      sx={theme => ({
        cursor: 'pointer',
        a: {
          display: 'inline-block',
          position: 'relative',
          height: theme.spacing(5.5)
        }
      })}
    >
      {isNextLink && href ? (
        <Link href={href} onClick={() => events.Global_Navigation_Clicked_FlowcodeLogo()}>
          {logoImage}
        </Link>
      ) : (
        <a
          href={href?.toString() || FLOWCODE_MARKETING_BASE_URL}
          rel="noopener noreferrer"
          onClick={() => events.Global_Navigation_Clicked_FlowcodeLogo()}
          aria-label="Flowcode homepage"
        >
          {logoImage}
        </a>
      )}
    </Stack>
  )
}
